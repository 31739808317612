
svg .country {
    stroke: #fff;
    stroke-width: 1px;
    cursor: pointer;
}
  svg .country:hover {
        stroke: red;
        stroke-width: 1px;
        cursor: pointer;
    }
.label{
    display:block;
    padding:12px;
    background: red;
}
/*#root {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    height: 100vh;
    padding: 0 50px 150px;
    text-align: center;
    box-sizing: border-box;
}*/

